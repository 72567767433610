import { $t } from '@/i18n/index'

export const saleOptions = [
  {
    label: $t('button.dateTime'),
    value: 'DATETIME'
  },
  {
    label: $t('project.costBasis.journalNo'),
    value: 'JOURNAL_NO'
  },
  {
    label: $t('report.referenceNo'),
    value: 'REFERENCE_NO'
  },
  {
    label: $t('report.Quantity'),
    value: 'QUANTITY'
  },
  {
    label: $t('title.additionalItem'),
    value: 'ADDITIONAL_ITEM'
  }
]

export const inventoryOptions = [
  {
    label: $t('button.dateTime'),
    value: 'DATETIME'
  },
  {
    label: $t('project.costBasis.journalNo'),
    value: 'JOURNAL_NO'
  },
  {
    label: $t('report.referenceNo'),
    value: 'REFERENCE_NO'
  },
  {
    label: $t('report.Quantity'),
    value: 'QUANTITY'
  },
  {
    label: $t('title.additionalItem'),
    value: 'ADDITIONAL_ITEM'
  }
]

export const valuationFilterSaveType = {
  FUTURE: 'FUTURE',
  OPTIONS: 'OPTIONS',
  VALIDATOR: 'VALIDATOR',
  VALIDATOR_RAW_DATA: 'VALIDATOR_RAW_DATA'
}

export const rawDataFilterType = {
  ENDING: 'ENDING',
  REALIZED: 'REALIZED',
  WITHDRAW: 'WITHDRAW',
  DEPOSIT: 'DEPOSIT'
}

export const adjustmentOriginOptions = [
  {
    label: $t('common.exchange'),
    value: 'EXCHANGE'
  },
  {
    label: $t('common.client'),
    value: 'CLIENT'
  }
]

export const priceProviderTypeOptions = [
  {
    label: $t('valuation.price.coingeckoAPI'),
    svgName: 'coingecko-icon',
    value: 'API_COINGECKO'
  },
  {
    label: $t('valuation.price.exchangeRateAPI'),
    svgName: 'exchange-rate-icon',
    value: 'API_EXCHANGE_RATE'
  },
  {
    label: 'CSV',
    svgName: 'csv-file',
    value: 'CSV'
  },
  {
    label: $t('common.openApi'),
    svgName: 'openApi-icon',
    value: 'OPENAPI'
  }
]

export const validatorFactType = [
  {
    label: $t('common.deposit'),
    value: 'DEPOSIT'
  },
  {
    label: $t('common.withdraw'),
    value: 'WITHDRAW'
  },
  {
    label: $t('common.balance'),
    value: 'BALANCE'
  }
]

export const derivativeTypeOptions = [
  { value: 'FUTURE', label: $t('automation.futureDerivativeType') },
  { value: 'OPTION', label: $t('automation.optionDerivativeType') },
  { value: 'VALIDATOR', label: $t('automation.validatorDerivativeType') },
  { value: 'CRYPTO_DISPOSAL', label: $t('automation.cryptoDisposalDerivativeType') },
  { value: 'CRYPTO_ADJUSTING', label: $t('automation.cryptoAdjustingDerivativeType') }
]
